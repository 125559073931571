import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronRight,
  faChevronDown,
  faCheck,
  faTimes,
  faSpinner,
  faHouse,
  faArrowRightFromBracket,
  faCalendarPlus,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faChevronRight,
  faChevronDown,
  faCheck,
  faTimes,
  faSpinner,
  faHouse,
  faArrowRightFromBracket,
  faCalendarPlus
);
